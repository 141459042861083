import React, {useEffect, useRef, useState} from "react";
import * as THREE from "three";
import {css, styled} from "styled-components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {DragDropContext, Draggable, Droppable} from "@hello-pangea/dnd";

import TextForm from "./TextForm";
import ImageUpload from "./ImageUpload";
import ElementControls from "./ElementControls";

const ArrowDownIcon = styled(KeyboardArrowDownIcon)`
    ${(props) =>
        props.active &&
        css`
            transform: rotate(180deg);
        `}
    transition: transform 300ms ease-in-out !important;
`;

const LeftControls = ({elements, setElements}) => {
    const [text, setText] = useState("");
    const [textColor, setTextColor] = useState("#000");
    const [activeSectionId, setActiveSectionId] = useState(null);
    const [loading, setLoading] = useState(false);

    const [maxId, setMaxId] = useState(0);
    const idCounter = useRef(maxId + 1);

    // Track max ID in useEffect
    useEffect(() => {
        if (elements.length > 0) {
            setMaxId(Math.max(...elements.map((e) => e.id)));
            idCounter.current = Math.max(...elements.map((e) => e.id)) + 1;
            console.log(elements);
        }
    }, [elements]);

    const generateNextId = () => {
        return idCounter.current++;
    };
    // Sort elements by ordering
    const sortElementsByOrdering = (elements) => {
        return elements.sort((a, b) => a.ordering - b.ordering);
    };

    const handleAddText = (e) => {
        e.preventDefault();
        if (text.trim() === "") return;

        setLoading(true);
        const newOrdering =
            (elements.length > 0
                ? Math.max(...elements.map((e) => e.ordering))
                : 0) + 1;

        setElements((prev) => {
            const newElements = [
                ...prev,
                {
                    id: generateNextId(),
                    ordering: newOrdering,
                    type: "text",
                    color: textColor,
                    content: text.trim(),
                    position: new THREE.Vector3(0, 0, 0),
                    rotation: new THREE.Euler(0, -Math.PI / 2, 0),
                    scale: new THREE.Vector3(1, 1, 1),
                    edit: true,
                    size: 18,
                },
            ];
            return sortElementsByOrdering(newElements);
        });
        setText("");
        setLoading(false);
    };

    const handleAddImage = (event) => {
        setLoading(true);
        const file = event.target.files[0];
        const reader = new FileReader();
        const newOrdering =
            (elements.length > 0
                ? Math.max(...elements.map((e) => e.ordering))
                : 0) + 1;

        reader.onload = (e) => {
            const textureLoader = new THREE.TextureLoader();
            textureLoader.load(
                e.target.result,
                (texture) => {
                    if (texture) {
                        texture.repeat.set(1, 1); // Set texture to show only once
                        setElements((prev) => {
                            const newElements = [
                                ...prev,
                                {
                                    id: generateNextId(),
                                    ordering: newOrdering,
                                    type: "image",
                                    texture,
                                    position: new THREE.Vector3(0, 0, 0),
                                    rotation: new THREE.Euler(
                                        0,
                                        -Math.PI / 2,
                                        0
                                    ),
                                    scale: new THREE.Vector3(0.5, 0.5, 0.5),
                                    edit: true,
                                },
                            ];
                            return sortElementsByOrdering(newElements);
                        });
                    } else {
                        console.error("Failed to load texture.");
                        setLoading(false);
                    }
                },
                undefined,
                (error) => {
                    console.error("Error loading texture:", error);
                    setLoading(false);
                }
            );
        };
        reader.readAsDataURL(file);
        document.getElementById("image-input").value = "";
        setLoading(false);
    };

    const deleteElement = (id) => {
        const newElements = elements.filter((element) => element.id !== id);
        return sortElementsByOrdering(newElements).map((element, index) => ({
            ...element,
            ordering: index + 1,
        }));
    };

    const handleDelete = (id) => {
        setLoading(true);
        const newElements = deleteElement(id);
        setElements(newElements);
        setLoading(false);
    };

    // Reorder elements after drag
    const reorderElements = (result) => {
        const {destination, source} = result;

        // Create a copy of the elements array
        const newElements = Array.from(elements);
        console.log(`New Elements in reorderElements Function: `);
        console.log(newElements);
        console.log("-------------------------------------------------");

        // Remove the dragged element
        const [removed] = newElements.splice(source.index, 1);
        console.log(`reordered Elements thats called [removed] : `);
        console.log(removed);
        console.log("-------------------------------------------------");

        // Insert the removed element at the destination index
        newElements.splice(destination.index, 0, removed);
        console.log(`newElements after splice : `);
        console.log(newElements);
        console.log("-------------------------------------------------");

        // Return the reordered elements with updated ordering
        return newElements.map((el, idx) => ({
            ...el,
            ordering: idx + 1,
        }));
    };
    const handleDragEnd = (result) => {
        const {destination, source} = result;
        if (!destination) {
            console.log("No destination for drag result");
            return;
        }

        console.log("Drag result:", result);
        console.log("Source index:", source.index);
        console.log("Destination index:", destination.index);

        setLoading(true);
        const newElements = reorderElements(result);
        console.log("New elements after reordering:", newElements);
        setElements(newElements);
        setLoading(false);
    };

    return (
        <div className="flex flex-col gap-4 flex-1">
            <TextForm
                text={text}
                setText={setText}
                textColor={textColor}
                setTextColor={setTextColor}
                handleAddText={handleAddText}
                loading={loading}
            />
            <ImageUpload handleAddImage={handleAddImage} loading={loading} />
            <div className="flex flex-col w-full gap-2 overflow-y-scroll h-48">
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}>
                                {elements.map((element, index) => (
                                    <Draggable
                                        key={index}
                                        draggableId={`${index}`}
                                        index={index}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}>
                                                <div className="flex flex-row justify-center items-center gap-3">
                                                    <span
                                                        {...provided.dragHandleProps}>
                                                        <DragIndicatorIcon className="cursor-grab" />
                                                    </span>
                                                    <button
                                                        className="bg-gray-200 hover:bg-gray-300 py-2 px-4 rounded w-full flex flex-row justify-between"
                                                        onClick={() =>
                                                            setActiveSectionId(
                                                                (prev) =>
                                                                    prev ===
                                                                    index
                                                                        ? null
                                                                        : index
                                                            )
                                                        }>
                                                        <span>
                                                            {element.type ===
                                                            "text"
                                                                ? element.content
                                                                : `Image ${
                                                                      index + 1
                                                                  }`}
                                                        </span>
                                                        <div>
                                                            <ArrowDownIcon
                                                                active={
                                                                    activeSectionId ===
                                                                    index
                                                                        ? "true"
                                                                        : "false"
                                                                }
                                                            />
                                                        </div>
                                                    </button>
                                                    <span className="rounded-sm bg-red-700 p-1 hover:bg-red-900">
                                                        <DeleteIcon
                                                            className="text-white cursor-pointer"
                                                            onClick={() =>
                                                                handleDelete(
                                                                    element.id
                                                                )
                                                            }
                                                        />
                                                    </span>
                                                </div>
                                                {activeSectionId === index && (
                                                    <ElementControls
                                                        element={element}
                                                        index={index}
                                                        handleElementChange={(
                                                            key,
                                                            value
                                                        ) => {
                                                            const newElements = [
                                                                ...elements,
                                                            ];
                                                            newElements[index][
                                                                key
                                                            ] = value;
                                                            setElements(
                                                                newElements
                                                            );
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </div>
    );
};

export default LeftControls;
