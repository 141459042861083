import {useControls, useStoreContext} from "leva";
import React, {useEffect} from "react";
import * as THREE from "three";
const TextControls = ({setElements, element, index, side}) => {
    useControls(`${side}-Element-${index + 1}`, {
        content: {
            value: `${element.content}` || null,
            onChange: (e) => {
                setElements((prev) =>
                    prev.map((el, i) =>
                        el.id === element.id
                            ? {
                                  ...el,
                                  content: e,
                              }
                            : el
                    )
                );
            },
        },
        color: {
            value: element.color || null,
            onChange: (e) => {
                setElements((prev) =>
                    prev.map((el, i) =>
                        el.id === element.id
                            ? {
                                  ...el,
                                  color: e,
                              }
                            : el
                    )
                );
            },
        },
        position: {
            value:
                [element.position.x, element.position.y, element.position.z] ||
                null,
            min: -0.2,
            max: 0.2,
            step: 0.001,
            onChange: (e) => {
                setElements((prev) =>
                    prev.map((el, i) =>
                        el.id === element.id
                            ? {
                                  ...el,
                                  position: new THREE.Vector3(...e),
                              }
                            : el
                    )
                );
            },
        },
        scale: {
            value: [element.scale.x, element.scale.y, element.scale.z] || null,
            min: 0.4,
            max: 1.5,
            step: 0.01,
            onChange: (e) => {
                setElements((prev) =>
                    prev.map((el, i) =>
                        el.id === element.id
                            ? {
                                  ...el,
                                  scale: new THREE.Vector3(...e),
                              }
                            : el
                    )
                );
            },
        },
        edit: {
            value: element.edit || true,
            onChange: (e) => {
                setElements((prev) => {
                    console.log(e)
                    return prev.map((el, i) =>
                        el.id === element.id
                            ? {
                                  ...el,
                                  edit: e,
                              }
                            : el
                    )
                }
                );
            },
        },
    });
    return null;
};

export default TextControls;
