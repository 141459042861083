import React from "react";
import * as THREE from "three";
import { PivotControls, Decal } from "@react-three/drei";
import createTextTexture from "./createTexture/createTextTexture";
import TextControls from "./TextControls";
import ImageControls from "./ImageControls";

const DecalElement = ({
    element,
    setElements,
    index,
    mesh,
    side,
}) => {
    // Define initial rotation based on side
    const initialRotation = side === "front"
        ? new THREE.Euler(0, 0, 0)
        : side === "back"
        ? new THREE.Euler(0, Math.PI, 0)
        : side === "right"
        ? new THREE.Euler(0, Math.PI / 2, 0)
        : side === "left"
        ? new THREE.Euler(0, -Math.PI / 2, 0)
        : new THREE.Euler(0, 0, 0); // default to front rotation

    return (
        <>
            {element.type === "text" && (
                <TextControls
                    type={element.type}
                    element={element}
                    index={index}
                    setElements={setElements}
                    side={side}
                />
            )}
            {element.type === "image" && (
                <ImageControls
                    type={element.type}
                    element={element}
                    index={index}
                    setElements={setElements}
                    side={side}
                />
            )}
            <PivotControls
                offset={
                    side === "front"
                        ? [0, 0, 0.2]
                        : side === "back"
                        ? [0, 0, -0.2]
                        : side === "right"
                        ? [0.3, 0, 0]
                        : side === "left"
                        ? [-0.3, 0, 0]
                        : [0, 0, 0] // default offset
                }
                rotation={initialRotation} // Use initial rotation if no rotation set
                key={index}
                activeAxes={[true, true, false]}
                enabled={element.edit}
                autoTransform
                depthTest={true}
                scale={0.2}
                onDrag={(local) => {
                    // Decompose the transformation matrix
                    const newPos = new THREE.Vector3(...element.position);
                    const newScale = new THREE.Vector3(...element.scale);
                    const newQuat = new THREE.Quaternion();
                    const newRot = new THREE.Euler(...element.rotation);
                    local.decompose(newPos, newQuat, newScale);

                    // Update rotation using quaternion directly
                    newRot.setFromQuaternion(newQuat);

                    // Update the element's position, rotation, and scale
                    setElements((prev) =>
                        prev.map((prevElement) =>
                            prevElement.id === element.id
                                ? {
                                      ...prevElement,
                                      position: newPos.toArray(),
                                      rotation: newRot.toArray(),
                                      scale: newScale.toArray(),
                                  }
                                : prevElement
                        )
                    );
                }}
            >
                <mesh ref={mesh} geometry={mesh.geometry}>
                    <Decal
                        position={element.position}
                        rotation={element.rotation}
                        scale={element.scale}
                        mesh={mesh}
                        geometry={mesh.geometry}
                        map={
                            element.type === "image"
                                ? element.texture
                                : createTextTexture(
                                      element.content,
                                      element.color,
                                      element.size
                                  )
                        }
                        material-depthTest={true}
                    />
                </mesh>
            </PivotControls>
        </>
    );
};

export default DecalElement;
