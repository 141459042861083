import {useControls, useStoreContext} from "leva";
import React, {useEffect} from "react";
import * as THREE from "three";
const ImageControls = ({setElements, element, index, side}) => {
    // Check if the image is an HTMLImageElement and get the src attribute
    let imagePath = "";
    const image = element.texture.image;
    if (image instanceof HTMLImageElement) {
        imagePath = image.src;
    } else if (image && image.src) {
        // This handles other types of images like canvas or video
        imagePath = URL.createObjectURL(image.src);
    }
    console.log(image);
    useControls(`${side}-Element-${index + 1}`, {
        image: {
            image: {
                value: imagePath || null, // Default to current texture image path
                onChange: (e) => {
                    // Load new texture when image path changes
                    new THREE.TextureLoader().load(e, (newTexture) => {
                        setElements((prev) =>
                            prev.map((el) =>
                                el.id === element.id
                                    ? {...el, texture: newTexture}
                                    : el
                            )
                        );
                    });
                },
            },
        },
        position: {
            value: [element.position.x, element.position.y, element.position.z] || null,
            min: -0.2,
            max: 0.2,
            step: 0.001,
            onChange: (e) => {
                setElements((prev) =>
                    prev.map((el, i) =>
                        el.id === element.id
                            ? {
                                  ...el,
                                  position: new THREE.Vector3(...e),
                              }
                            : el
                    )
                );
            },
        },
        scale: {
            value: [element.scale.x, element.scale.y, element.scale.z] || null,
            min: 0.4,
            max: 1.5,
            step: 0.01,
            onChange: (e) => {
                setElements((prev) =>
                    prev.map((el, i) =>
                        el.id === element.id
                            ? {
                                  ...el,
                                  scale: new THREE.Vector3(...e),
                              }
                            : el
                    )
                );
            },
        },
        edit: {
            value: element.edit || true,
            onChange: (e) => {
                setElements((prev) =>
                    prev.map((el, i) =>
                        el.id === element.id
                            ? {
                                  ...el,
                                  edit: e,
                              }
                            : el
                    )
                );
            },
        },
    });
    return null;
};

export default ImageControls;
