// CameraController.jsx
import { useEffect, useState } from "react";
import { useThree, useFrame } from "@react-three/fiber";
import * as THREE from "three";

const cameraPositions = {
    front: new THREE.Vector3(0, 0, 3),
    back: new THREE.Vector3(0, 0, -3),
    right: new THREE.Vector3(2, 1, -0.1),
    left: new THREE.Vector3(-2, 1, -0.1),
};

const CameraController = ({ cameras, side }) => {
    const { camera } = useThree();
    const [targetPosition, setTargetPosition] = useState(
        camera.position.clone()
    );
    const [positionChanged, setPositionChanged] = useState(false);

    // CAMERA CONTROLLING
    useEffect(() => {
        if (cameras && cameras.length > 0) {
            const gltfCamera = cameras[0];
            camera.position.copy(gltfCamera.position);
            camera.rotation.copy(gltfCamera.rotation);
            camera.fov = gltfCamera.fov;
            camera.updateProjectionMatrix();
        }
    }, [cameras, camera]);
    useFrame(() => {
        if (positionChanged && targetPosition) {
            camera.position.lerp(targetPosition, 0.05);
            camera.lookAt(0, 0, 0);
            camera.updateProjectionMatrix();
            if (camera.position.distanceTo(targetPosition) < 0.01) {
                setPositionChanged(false);
            }
        }
    });
    useEffect(() => {
        setTargetPosition(cameraPositions[side] || camera.position.clone());
        setPositionChanged(true);
    }, [side]);

    return null;
};

export default CameraController;
