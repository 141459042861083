// TShirtColorController.jsx
import { useEffect } from "react";
import * as THREE from "three";

const TShirtColorController = ({ scene, tShirtColor1, tShirtColor2 }) => {
    useEffect(() => {
        scene.traverse((child) => {
            if (child.isMesh) {
                if (
                    child.name === "polySurface37_re_work_phong1__0" ||
                    child.name === "polySurface3_re_work_phong1__0" ||
                    child.name === "polySurface38_re_work_phong1__0"
                ) {
                    child.material = new THREE.MeshStandardMaterial({
                        color: tShirtColor2,
                    });
                }
                if (
                    child.name === "ShirtpolySurface36_re_work_phong1__0" ||
                    child.name === "ShirtpolySurface38_re_work_phong1__0" ||
                    child.name === "ShirtpolySurface39_re_work_phong1__0" ||
                    child.name === "polySurface4_re_work_phong1__0"
                ) {
                    child.material = new THREE.MeshStandardMaterial({
                        color: tShirtColor1,
                    });
                }
            }
            child.castShadow = true;
        });
    }, [tShirtColor1, tShirtColor2, scene]);

    return null;
};

export default TShirtColorController;
