import React, {useEffect, useRef, useState} from "react";
import {Decal, PivotControls, useGLTF} from "@react-three/drei";
import {useFrame, useThree} from "@react-three/fiber";
import * as THREE from "three";
import {DecalGeometry} from "three/examples/jsm/geometries/DecalGeometry";
import CameraController from "./CameraController";
import DecalMeshGroup from "./DecalMeshGroup";
import TShirtColorController from "./TShirtColorController";

const createTextTexture = (text, color, fontSize = 64) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    canvas.width = 512;
    canvas.height = 512;
    context.font = `${fontSize}px Arial`;
    context.fillStyle = color;
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillText(text, canvas.width / 2, canvas.height / 2);
    return new THREE.CanvasTexture(canvas);
};

const cameraPositions = {
    front: new THREE.Vector3(0, 0, 3),
    back: new THREE.Vector3(0, 0, -3),
    right: new THREE.Vector3(2, 1, -0.1), // Closer to the model
    left: new THREE.Vector3(-2, 1, -0.1), // Closer to the model
};

//IMPORTING IMAGES
function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => {
        images[item.replace("./", "")] = r(item);
    });
    return images;
}

const Model = ({
    side,
    tShirtColor1,
    tShirtColor2,
    frontElements,
    setFrontElements,
    backElements,
    setBackElements,
    leftElements,
    setLeftElements,
    rightElements,
    setRightElements,
}) => {
    const model = importAll(require.context("./model", true, /\.(glb)$/));
    const {scene, cameras} = useGLTF(model["polo_white_tshirt.glb"]);

    return (
        <>
            <primitive object={scene} />
            <TShirtColorController
                scene={scene}
                tShirtColor1={tShirtColor1}
                tShirtColor2={tShirtColor2}
            />
            <DecalMeshGroup
                scene={scene}
                elements={frontElements}
                setElements={setFrontElements}
                meshName="ShirtpolySurface36_re_work_phong1__0"
                orientation={new THREE.Euler(0, 0, 0)}
                side="front"
            />
            <DecalMeshGroup
                scene={scene}
                elements={backElements}
                setElements={setBackElements}
                meshName="polySurface4_re_work_phong1__0"
                orientation={new THREE.Euler(0, Math.PI, 0)}
                side="back"
            />
            <DecalMeshGroup
                scene={scene}
                elements={rightElements}
                setElements={setRightElements}
                meshName="ShirtpolySurface38_re_work_phong1__0"
                orientation={new THREE.Euler(0, Math.PI / 2, 0)}
                side="right"
                yOffset={0.2}
            />
            <DecalMeshGroup
                scene={scene}
                elements={leftElements}
                setElements={setLeftElements}
                meshName="ShirtpolySurface39_re_work_phong1__0"
                orientation={new THREE.Euler(0, -Math.PI / 2, 0)}
                side="left"
                yOffset={0.2}
            />
            <CameraController side={side} cameras={cameras} />
        </>
    );
};

export default Model;
