import {useEffect, useRef} from "react";
import * as THREE from "three";
import {DecalGeometry} from "three/examples/jsm/geometries/DecalGeometry";
import {Decal, PivotControls} from "@react-three/drei";
import {useControls} from "leva";
import DecalElement from "./DecalElement";
import createTextTexture from "./createTexture/createTextTexture";

const DecalMeshGroup = ({
    scene,
    elements,
    setElements,
    meshName,
    orientation,
    side,
    yOffset = 0,
}) => {
    const decalMeshRef = useRef(new THREE.Group());
    const meshRef = useRef();

    useEffect(() => {
        const updateElements = () => {
            scene.traverse((child) => {
                if (child.isMesh && child.name === meshName) {
                    meshRef.current = child;
                    elements.forEach((element, index) => {
                        const texture =
                            element.type === "image"
                                ? element.texture
                                : createTextTexture(
                                      element.content,
                                      element.color,
                                      element.size
                                  );

                        if (texture) {
                            texture.repeat.set(1, 1);
                            const decalMaterial = new THREE.MeshBasicMaterial({
                                map: texture,
                                transparent: true,
                                depthTest: true,
                                depthWrite: true,
                                polygonOffset: true,
                                polygonOffsetFactor: -1,
                            });

                            const decalPosition = new THREE.Vector3(
                                ...element.position
                            );
                            const decalScale = new THREE.Vector3(
                                ...element.scale
                            );
                            const decalRotation = new THREE.Euler(
                                ...element.rotation
                            );

                            const decalGeometry = new DecalGeometry(
                                child,
                                decalPosition,
                                decalRotation,
                                decalScale,
                            );

                            const decalMesh = new THREE.Mesh(
                                decalGeometry,
                                decalMaterial
                            );

                            decalMesh.renderOrder = element.ordering * -1;
                            decalMeshRef.current.add(decalMesh);
                            decalMesh.material.needsUpdate = true;
                        }
                    });
                }
            });
        };

        if (decalMeshRef.current) {
            while (decalMeshRef.current.children.length) {
                decalMeshRef.current.remove(decalMeshRef.current.children[0]);
            }
        }

        updateElements();
        console.log(elements)
    }, [elements]);

    return (
        <>
            <group ref={decalMeshRef} />
            {elements.map((element, index) => (
                <DecalElement
                    key={index}
                    mesh={meshRef.current}
                    decalMeshRef={decalMeshRef}
                    element={element}
                    setElements={setElements}
                    index={index}
                    yOffset={yOffset}
                    side={side}
                    orientation={orientation}
                />
            ))}
        </>
    );
};

export default DecalMeshGroup;
